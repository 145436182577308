<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-data-table
        :headers="headers"
        :items="items"
        sort-by="category"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-if="dialog" v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-dialog v-model="dialog" max-width="1200px">
              <v-card>
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step
                      class="cursor_pointer"
                      step="1"
                      @click="e1 = 1"
                    >
                      Основная информация
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step
                      class="cursor_pointer"
                      step="2"
                      @click="e1 = 2"
                    >
                      Временной тариф
                    </v-stepper-step>
                    <v-divider />
                  </v-stepper-header>
                  <v-stepper-content step="1">
                    <first-step-food-tariff
                      :is_edit="is_edit"
                      :food-type-list="foodTypeList"
                      :mainTemplate="editedItem"
                      ref="firststepfoodtariff"
                    />
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-card-text>
                      <v-container>
                        <v-row v-if="editedItem.food_type === 'breakfast' || editedItem.food_type === 'all'">
                          <v-col cols="6">
                            Завтрак
                            <v-row>
                                <v-col>
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="editedItem.breakfast_start_time"
                                                    label="Время начала"
                                                    prepend-icon="mdi-clock-time-four"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-model="editedItem.breakfast_start_time"
                                                full-width
                                                format="24hr"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="editedItem.breakfast_end_time"
                                                    label="Время окончания"
                                                    prepend-icon="mdi-clock-time-four"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-model="editedItem.breakfast_end_time"
                                                full-width
                                                format="24hr"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row v-if="editedItem.food_type === 'lunch' || editedItem.food_type === 'all'">
                          <v-col cols="6">
                            Обед
                            <v-row>
                                <v-col>
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="editedItem.lunch_start_time"
                                                    label="Время начала"
                                                    prepend-icon="mdi-clock-time-four"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-model="editedItem.lunch_start_time"
                                                full-width
                                                format="24hr"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="editedItem.lunch_end_time"
                                                    label="Время окончания"
                                                    prepend-icon="mdi-clock-time-four"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-model="editedItem.lunch_end_time"
                                                full-width
                                                format="24hr"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row v-if="editedItem.food_type === 'supper' || editedItem.food_type === 'all'">
                          <v-col cols="6">
                            Ужин
                            <v-row>
                                <v-col>
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="editedItem.supper_start_time"
                                                    label="Время начала"
                                                    prepend-icon="mdi-clock-time-four"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-model="editedItem.supper_start_time"
                                                full-width
                                                format="24hr"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="editedItem.supper_end_time"
                                                    label="Время окончания"
                                                    prepend-icon="mdi-clock-time-four"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-model="editedItem.supper_end_time"
                                                full-width
                                                format="24hr"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-stepper-content>
                </v-stepper>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" text @click="close"> Отмена </v-btn>
                  <v-btn
                    v-if="e1 === 2"
                    color="primary"
                    text
                    @click="update"
                    :disabled="disabledBtn"
                  >
                    Сохранить
                  </v-btn>
                  <v-btn v-else color="primary" text @click="nextStep">
                    Далее
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.food_type`]="{ item }">
          {{ foodTypes[item.food_type] }}
        </template>
        <template v-slot:[`item.index`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="red darken-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-btn class="ma-3" color="primary" @click="createTariff">
        Создать тариф питания
      </v-btn>
    </v-card>
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Вы уверены? </v-card-title>
        <v-card-text>Тариф удалится безвозвратно!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDelete = false">
            Отмена
          </v-btn>
          <v-btn color="green darken-1" text @click="remove"> Согласен </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import toast from "../../../plugins/toast";
import { TariffsApiUrls } from "@/services/accommodationRequests/tariffs.api.js";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";
import moment from "moment";
import {FoodTariffsApiUrls} from "@/services/accommodationRequests/foodTariffs.api";
import FirstStepFoodTariff from "@/views/main/components/foodTariff/FirstStep.vue";
import Food from "@/views/main/pages/Food.vue";

export default {
  name: "FoodTariff",
  components: {
    FirstStepFoodTariff,
  },
  data: () => ({
    disabledBtn: false,
    firstWatcherTrigger: false,
    totalItems: 0,
    options: {},
    tariffForDelete: null,
    dialogDelete: false,
    is_edit: false,
    e1: 1,
    dialog: false,
    headers: [
      { text: "№", value: "index", class: "table-title" },
      { text: "Тариф питания", value: "food_type", class: "table-title" },
      {
        text: "Тип оплаты",
        value: "price_type",
        sortable: true,
        class: "table-title"
      },
      {
        text: "Дата создания",
        value: "created_at",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Действие",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      breakfast_end_time: "",
      breakfast_start_time: "",
      company: 0,
      created_at: "",
      deleted: false,
      food_type: "",
      id: null,
      lunch_end_time: "",
      lunch_start_time: "",
      months: [],
      price_type: "weekdays",
      show_on_other_services: true,
      supper_end_time: "",
      supper_start_time: "",
      updated_at: "",
      weekdays: []
    },
    foodTypeList: [
      {
        text: "Завтрак",
        value: "breakfast",
      },
      {
        text: "Обед",
        value: "lunch",
      },
      {
        text: "Ужин",
        value: "supper",
      },
      {
        text: "Трехразовое питание",
        value: "all",
      },
    ],
    foodTypes: {
      breakfast: "Завтрак",
      lunch: "Обед",
      supper: "Ужин",
      all: "Трехразовое питание",
    },
    priceTypes: [
      { name: "Взрослый заранее", value: "adult" },
      { name: "Взрослый на месте", value: "adult_on_reception" },
      { name: "Детский заранее", value: "children" },
      { name: "Детский на месте", value: "children_on_reception" },
    ],
    loading: false
  }),
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.loadRow();
    this.initializeTable();
  },
  methods: {
    // добавить в editedItem months, weekdays
    initializeTable() {
      this.editedItem.months = [];
      this.editedItem.weekdays = [];
      this.foodTypeList.forEach(priceType => {
        this.editedItem.months.push({
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
          price_type: priceType.value,
          name: priceType.text
        });
        this.editedItem.weekdays.push({
          monday_price: 0,
          tuesday_price: 0,
          wednesday_price: 0,
          thursday_price: 0,
          friday_price: 0,
          saturday_price: 0,
          sunday_price: 0,
          price_type: priceType.value,
          name: priceType.text
        });
      });
    },
    // добавить в уже готовый editedItem с левой стороны названия { Основной тариф, ... }
    addLeftNames() {
      this.priceTypes.forEach(priceType => {
        this.editedItem.months.forEach(month => {
          if (month.price_type === priceType.value) month.name = priceType.name;
        });
        this.editedItem.weekdays.forEach(week => {
          if (week.price_type === priceType.value) week.name = priceType.name;
        });
      });
    },
    // delete tariff
    async remove() {
      this.dialogDelete = false;
      await FoodTariffsApiUrls.deleteTariff(this.tariffForDelete);
      this.items = [];
      this.loadRow();
    },
    // переход на следующий step в модалке
    nextStep() {
      switch (this.e1) {
        case 1:
          if (!this.editedItem.food_type) {
            toast.error("Выберите тип питания");
            return;
          }
          break;
        default:
      }
      this.e1 = this.e1 + 1;
    },
    // загрузить тарифы и категории
    async loadRow() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      let res = await FoodTariffsApiUrls.getTariffs(params);
      this.totalItems = res.meta.pagination.count;
      res.results.forEach(item => {
        item.created_at = moment(item.created_at * 1000).format("DD.MM.YYYY");
        item.price_type =
          item.price_type === "weekdays" ? "По дням недели" : "За период";
      });
      this.items = res.results;
      let params_2 = {
        "page[size]": 10000,
        "page[number]": 1
      };
      this.loading = false;

      let response = await CategoriesApiUrls.getCategories(params_2);
      response.forEach(item => {
        item.value = item.id;
        item.text = item.name;
      });
      this.categoryList = response;
    },
    // открыть модалку с квизом для создания тарифа
    createTariff() {
      this.is_edit = false;
      this.editedItem = {
        breakfast_end_time: "",
        breakfast_start_time: "",
        company: 0,
        created_at: "",
        deleted: false,
        food_type: "breakfast",
        id: null,
        lunch_end_time: "",
        lunch_start_time: "",
        months: [],
        price_type: "weekdays",
        show_on_other_services: true,
        supper_end_time: "",
        supper_start_time: "",
        updated_at: "",
        weekdays: []
      }
      this.dialog = true;
    },
    // открыть модалку с квизом для изменения определенного тарифа
    async editItem(item) {
      this.initializeTable();
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      let resp = await FoodTariffsApiUrls.getTariff(this.editedItem.id);
      this.editedItem = { ...resp };
      this.addLeftNames();
      this.$forceUpdate();

      this.dialog = true;
      this.is_edit = true;
    },
    // открыть модалку для удаления тарифа
    deleteItem(item) {
      this.dialogDelete = true;
      this.tariffForDelete = item.id;
    },
    // закрыть модалку
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    // создать или изменить запросы тарифа
    async update() {
      if (this.editedIndex > -1) {
        delete this.editedItem.category;
        this.disabledBtn = true;

        try {
          await FoodTariffsApiUrls.setTariff(this.editedItem.id, this.editedItem);
          toast.success("Успешно изменено!");
          this.disabledBtn = false;
          this.items = [];
          this.loadRow();
          this.close();
        } catch (e) {
          this.disabledBtn = false;
        }
      } else {
        this.disabledBtn = true;
        for (const key in this.editedItem) {
          if (this.editedItem[key] === "") {
            delete this.editedItem[key];
          }
        }
        try {
          await FoodTariffsApiUrls.createTariff(this.editedItem);
          toast.success("Успешно создано!");
          this.disabledBtn = false;
          this.items = [];
          this.loadRow();
          this.close();
        } catch (e) {
          this.disabledBtn = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.cursor_pointer {
  cursor: pointer;
}
</style>
